import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import ContactForm from '../components/ContactForm';
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types';
import HeroInnerPage from '../components/HeroInnerPage';
import HeaderSpacer from '../components/HeaderSpacer';
import { StaticImage } from 'gatsby-plugin-image';
const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeaderSpacer filled />
      <Section>
        <Container>
          <div className="mx-auto max-w-screen-lg">
            <div className="flex flex-col gap-8 md:flex-row md:gap-16 lg:gap-32">
              <div className="prose grow">
                <ContactForm />
              </div>
              <div>
                <div
                  data-sal="slide-up"
                  className="mb-8"
                  style={{ '--sal-duration': '.5s' } as React.CSSProperties}
                >
                  <StaticImage
                    src="../images/contact.png"
                    alt=" "
                    placeholder="none"
                    layout="fixed"
                    width={196}
                    formats={['auto', 'webp', 'avif']}
                  />
                </div>
                <div className="prose divide-y">
                  <div className="py-4">
                    <p className="m-0 font-bold">{t('common.address_label')}</p>
                    <div dangerouslySetInnerHTML={{ __html: t('common.address') }} />
                  </div>
                  {/* <div className="py-4">
                    <p className="m-0 font-bold">{t('common.phone_label')}</p>
                    <div dangerouslySetInnerHTML={{ __html: t('common.phone') }} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default ContactPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  const { language } = pageContext as PageContext;

  type Title = {
    [key: string]: string;
  };

  const title = {
    fr: 'Contact',
    en: 'Contact',
  } as Title;

  return <Seo location={location} title={`${title[language]} | Austra`} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
