import React from 'react';
import Button from './Button';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import axios, { Axios } from 'axios';
import SectionTitle from './SectionTitle';
import Loading from './Loading';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

type Props = {};

const ContactForm = (props: Props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('validation.short', { length: 2 }))
      .max(50, t('validation.long', { length: 50 }))
      .required(t('validation.required')),
    lastName: Yup.string()
      .min(2, t('validation.short', { length: 2 }))
      .max(50, t('validation.long', { length: 50 }))
      .required(t('validation.required')),
    company: Yup.string()
      .min(2, t('validation.short', { length: 2 }))
      .max(50, t('validation.long', { length: 50 }))
      .required(t('validation.required')),
    email: Yup.string().email(t('validation.email')).required(t('validation.required')),
    message: Yup.string().required(t('validation.required')),
  });

  const handleSubmit = (values: any) => {
    const apiUrl = '/api/contact.php';
    setIsSubmitting(true);
    axios
      .post(apiUrl, values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {isSubmitted ? (
        <div>
          <h3>{t('common.form_send_title')}</h3>
          <p className="mb-4">{t('common.form_send_title_text')}</p>
        </div>
      ) : (
        <>
          <SectionTitle>{t('page.contact.title')}</SectionTitle>
          <h3 className="mb-8">{t('page.contact.subtitle')}</h3>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              preferPhone: false,
              company: '',
              message: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group">
                    <label htmlFor="firstName">{t('forms.firstname')}</label>
                    <Field className="form-input" name="firstName" id="firstName" />
                    {errors.firstName && touched.firstName ? (
                      <div className="form-error">{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">{t('forms.lastname')}</label>
                    <Field className="form-input" name="lastName" id="lastName" />
                    {errors.lastName && touched.lastName ? (
                      <div className="form-error">{errors.lastName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="company">{t('forms.company')}</label>
                  <Field className="form-input" name="company" id="company" />
                  {errors.lastName && touched.company ? (
                    <div className="form-error">{errors.company}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="email">{t('forms.email')}</label>
                  <Field className="form-input" name="email" id="email" type="email" />
                  {errors.email && touched.email ? (
                    <div className="form-error">{errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">
                    {t('forms.phone')} {t('forms.optional')}
                  </label>
                  <Field
                    className="form-input"
                    name="phone"
                    id="phone"
                    type="text"
                    inputMode="tel"
                  />
                  {errors.phone && touched.phone ? (
                    <div className="form-error">{errors.phone}</div>
                  ) : null}
                </div>
                {values.phone && (
                  <div className="form-group">
                    <div className="mb-3 flex items-center gap-2">
                      <Field
                        className="form-checkbox"
                        name="preferPhone"
                        id="preferPhone"
                        type="checkbox"
                      />
                      <label htmlFor="preferPhone">{t('forms.preferPhone')}</label>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="message">{t('forms.message')}</label>
                  <Field
                    className="form-input"
                    name="message"
                    id="message"
                    as="textarea"
                    rows="4"
                  />
                  {errors.email && touched.message ? (
                    <div className="form-error">{errors.message}</div>
                  ) : null}
                </div>
                <div className="mt-6">
                  <Button
                    type="submit"
                    size="lg"
                    variant="secondary"
                    disabled={isSubmitting}
                    className="w-full sm:w-auto"
                  >
                    {t('button.send')}

                    {isSubmitting ? (
                      <Loading variant="primary" />
                    ) : (
                      <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default ContactForm;
